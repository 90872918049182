import React, { useState, useEffect } from 'react';
import {
  getBoodschappenAsync,
  postBoodschapAsync,
  putBoodschapAsync,
  deleteBoodschapAsync,
  getAllAsync
} from './services/boodschappen-service';
import Modal from './components/modal';
import './App.css';

function App() {
  const initieel = {
    id: 0,
    product: '',
    prijs: 0,
    houdbaarheidsdatum: ''
  };

  const [boodschappen, setBoodschappen] = useState([]);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortPriceAsc, setSortPriceAsc] = useState(true);
  const [sortDateAsc, setSortDateAsc] = useState(true);
  const [formData, setFormData] = useState(initieel);
  const [idVerwijderen, setIdVerwijderen] = useState(-1);

  useEffect(() => {
    getAllAsync()
      .then(response => {
        console.log('response.data.medewerkers (https://www.mjhmhouben.com:3000)', response.data.medewerkers);
        //if (response.data != null) {
        //  console.table(response.data);
        //  setBoodschappen(response.data);
        //}
      })
      .catch(error => {
        console.error('Fout bij ophalen gegevens', error);
      });
  }, []);

  useEffect(() => {
    getBoodschappenAsync()
      .then(response => {
        if (response.data != null) {
          //console.table(response.data);
          setBoodschappen(response.data.boodschappen);
        }
      })
      .catch(error => {
        console.error('Fout bij ophalen gegevens', error);
      });
  }, []);

  const priceAsc = [...boodschappen].sort(function (a, b) {
    return a.prijs < b.prijs ? -1 : 1;
  });

  const priceDesc = [...boodschappen].sort(function (a, b) {
    return a.prijs < b.prijs ? 1 : -1;
  });

  const sortPriceHandler = () => {
    setSortPriceAsc(() => !sortPriceAsc);

    if (!sortPriceAsc) {
      setBoodschappen(priceAsc);
    } else {
      setBoodschappen(priceDesc);
    }
  };

  const dateAsc = [...boodschappen].sort(function (a, b) {
    let dateA = new Date(a.houdbaarheidsdatum);
    let dateB = new Date(b.houdbaarheidsdatum);
    return dateA < dateB ? -1 : 1;
  });

  const dateDesc = [...boodschappen].sort(function (a, b) {
    let dateA = new Date(a.houdbaarheidsdatum);
    let dateB = new Date(b.houdbaarheidsdatum);
    return dateA < dateB ? 1 : -1;
  });

  const sortDateHandler = () => {
    setSortDateAsc(() => !sortDateAsc);

    if (!sortDateAsc) {
      setBoodschappen(dateAsc);
    } else {
      setBoodschappen(dateDesc);
    }
  };

  const addBoodschapHandler = () => {
    setShowFormModal(showFormModal => !showFormModal);
  };

  const editBoodschapHandler = boodschap => {
    setFormData(boodschap);
    setShowFormModal(showFormModal => !showFormModal);
  };

  const deleteBoodschapHandler = id => {
    setIdVerwijderen(id);
    setShowDeleteModal(showDeleteModal => !showDeleteModal);
  };

  const boodschappenLijst = boodschappen.map(boodschap => {
    let myFormat = new Intl.NumberFormat('nl-NL', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2
    });
    const formattedPrice = `€${myFormat.format(boodschap.prijs)}`;

    return (
      <div key={boodschap.id} className="card">
        <div className="row">
          <div className="column">Product</div>
          <div className="column">{boodschap.product}</div>
          <div className="column">
            <button className="button" onClick={() => editBoodschapHandler(boodschap)}>
              <i className="bi bi-pencil"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="column">Prijs</div>
          <div className="column">{formattedPrice}</div>
          <div className="column">
            <button className="button" onClick={() => deleteBoodschapHandler(boodschap.id)}>
              <i className="bi bi-trash3"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="column">Houdbaarheid</div>
          <div className="column">{boodschap.houdbaarheidsdatum.split('-').reverse().join('-')}</div>
          <div className="column"></div>
        </div>
      </div>
    );
  });

  const inputHandler = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onCancelHandler = () => {
    setShowFormModal(showFormModal => !showFormModal);
    setFormData(initieel);
  };

  const onSubmitHandler = event => {
    event.preventDefault();

    if (formData.id === 0) {
      postBoodschapAsync(formData).then(response => {
        const nieuwId = response.data.id;
        setBoodschappen([
          ...boodschappen,
          {
            id: nieuwId,
            product: formData.product,
            prijs: formData.prijs,
            houdbaarheidsdatum: formData.houdbaarheidsdatum
          }
        ]);
      });
    } else {
      putBoodschapAsync(formData.id, formData);
      setBoodschappen(boodschappen.map(obj => (obj.id === formData.id ? formData : obj)));
    }
    setShowFormModal(showModal => !showModal);
    setFormData(initieel);
  };

  const closeModalHandler = () => {
    setShowFormModal(showFormModal => !showFormModal);
    setFormData(initieel);
  };

  const deleteJaHandler = () => {
    deleteBoodschapAsync(idVerwijderen);
    setBoodschappen(boodschappen.filter(b => b.id !== idVerwijderen));
    setShowDeleteModal(showDeleteModal => !showDeleteModal);
  };

  const deleteNeeHandler = () => {
    setShowDeleteModal(showDeleteModal => !showDeleteModal);
  };

  return (
    <>
      <div className="kader">
        <h1 className="card">Boodschappen Lijst</h1>
      </div>
      <div className="kader">
        <div className="card">
          <div className="row">
            <div className="column">
              <button className="button" onClick={sortPriceHandler}>
                Sort Prijs {sortPriceAsc ? 'Desc' : 'Asc'}
              </button>
            </div>
            <div className="column" onClick={sortDateHandler}>
              <button className="button">Sort Datum {sortDateAsc ? 'Desc' : 'Asc'}</button>
            </div>
            <div className="column">
              <button className="button" onClick={addBoodschapHandler}>
                <i className="bi bi-file-earmark-plus"></i>
              </button>
            </div>
          </div>
        </div>
        {boodschappenLijst}
      </div>

      <div>
        {showFormModal && (
          <Modal onClose={closeModalHandler}>
            <h1 className="h1">Boodschappen Formulier</h1>
            <form onSubmit={onSubmitHandler} className="form">
              <div className="control">
                <label htmlFor="product">Product</label>
                <input
                  className="input"
                  type="text"
                  id="product"
                  name="product"
                  size="90"
                  value={formData.product}
                  onChange={event => inputHandler(event)}
                  required
                  autoFocus
                />
              </div>

              <div className="control">
                <label htmlFor="prijs">Prijs</label>
                <input
                  className="input"
                  type="number"
                  id="prijs"
                  name="prijs"
                  min="0"
                  step="0.01"
                  value={formData.prijs}
                  onChange={event => inputHandler(event)}
                />
              </div>

              <div className="control">
                <label htmlFor="houdbaarheidsdatum">Houdbaarheidsdatum</label>
                <input
                  className="input"
                  type="date"
                  id="houdbaarheidsdatum"
                  name="houdbaarheidsdatum"
                  min="2023-01-01"
                  value={formData.houdbaarheidsdatum}
                  onChange={event => inputHandler(event)}
                />
              </div>

              <div className="rowModal">
                <button type="submit" className="columnModal buttonModal">
                  {formData.id === 0 ? 'Toevoegen' : 'Wijzigen'}
                </button>
                <button type="button" className="columnModal buttonModal" onClick={onCancelHandler}>
                  Annuleren
                </button>
              </div>
            </form>
          </Modal>
        )}
      </div>

      <div>
        {showDeleteModal && (
          <Modal onClose={() => setShowDeleteModal(showDeleteModal => !showDeleteModal)}>
            <h1 className="h1">Wil je dit verwijderen?</h1>
            <div className="rowModal">
              <button type="button" className="columnModal buttonModal" onClick={deleteJaHandler}>
                Ja
              </button>
              <button type="button" className="columnModal buttonModal" onClick={deleteNeeHandler}>
                Nee
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default App;
