import axios from 'axios';

const ENDPOINT = 'boodschappen';

export const getAllAsync = async () => {
  return axios.get(`https://www.mjhmhouben.com:3000`, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  });
};

export const getBoodschappenAsync = async () => {
  //return axios.get(`${process.env.REACT_APP_API_BASE_URL}/${ENDPOINT}`);
  return axios.get(`https://www.mjhmhouben.com:3002`);
};

export const getBoodschapAsync = async id => {
  return axios.get(`${process.env.REACT_APP_API_BASE_URL}/${ENDPOINT}/${id}`);
};

export const deleteBoodschapAsync = async id => {
  return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/${ENDPOINT}/${id}`);
};

export const putBoodschapAsync = async (id, boodschap) => {
  return axios.put(`${process.env.REACT_APP_API_BASE_URL}/${ENDPOINT}/${id}`, boodschap);
};

export const postBoodschapAsync = async boodschap => {
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/${ENDPOINT}`, boodschap);
};
