import React, { useState, useEffect, useRef } from 'react';
import PortalReactDOM from 'react-dom';
import classes from './modal.module.css';

const Backdrop = props => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = props => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const Modal = props => {
  const ref = useRef;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById('overlays');
    setMounted(true);
  }, []);

  return (
    <>
      {mounted && ref.current ? PortalReactDOM.createPortal(<Backdrop onClose={props.onClose} />, ref.current) : null}
      {mounted && ref.current
        ? PortalReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, ref.current)
        : null}
    </>
  );
};

export default Modal;
